import React from 'react';
import Layout from '../components/common/Layout.jsx';
import LandingBanner from '../components/landing/LandingBanner.jsx';
import LandingNavgrid from '../components/landing/LandingNavgrid.jsx';
import LandingApp from '../components/landing/LandingApp.jsx';
import { Helmet } from 'react-helmet';

class LandingPage extends React.PureComponent {
    render(){
        return (
            <Layout>
                <Helmet>
                    <meta http-equiv="Content-Type" content="text/html;charset=UTF-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <meta name="description" content="Read now for heart information in dogs."/>
                    <meta name="robots" content="index, follow"/>
                    <meta property="og:title" content="Healthy Hearts for Dogs - Boehringer Ingelheim"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:description" content="Read now for heart information in dogs."/>
                    <meta property="og:locale" content="en_AU"/>

                    <title>Healthy Hearts for Dogs - Boehringer Ingelheim 2019</title>
                    <meta name="apple-mobile-web-app-title" content="Healthy Hearts for Dogs - Boehringer Ingelheim"/>
                    <meta property="og:url" content="https://healthyheartsfordogs.com.au"/>
                    <link rel="canonical" href="https://healthyheartsfordogs.com.au"/>
                </Helmet>
                <LandingBanner/>
                <LandingNavgrid/>
                <LandingApp/>
            </Layout>
        )
    }
}

export default LandingPage;
